import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { download, post, get } from '@common-src/service/request';
import { assignDefaultExtend } from '@common-src/service/report';
import { getEndTimeByIntervalType, ReportExtraQueryModel } from './parking-report-model';
import { getLocation } from '@common-src/service/fc-user';
import { sourceList } from '@common-src/entity-model/fc-user-entity';
import { PeriodTypeEnum } from '@common-src/model/enum';

const MockData = [
    {
        'phone': '18114521111',
        'locationName': '方舱3栋/1F',
        'roomName': '3-1022',
        'source': '济阳区',
        'quarantineStart': 1652926402467,
        'actualQuarantineEnd': 1652996402467,
        'roomCount': 2,
        'accommodationCost': 100,
        'breakFast': 20,
        'lunch': 30,
        'dinner': 30
    },
    {
        'phone': '18114521111',
        'locationName': '方舱3栋/1F',
        'roomName': '3-1022',
        'source': '济阳区2',
        'quarantineStart': 1652926402467,
        'actualQuarantineEnd': 1652996402467,
        'roomCount': 2,
        'accommodationCost': 200,
        'breakFast': 15,
        'lunch': 25,
        'dinner': 35
    },
    {
        'phone': '18114521111',
        'locationName': '方舱3栋/1F',
        'roomName': '3-1022',
        'source': '济阳区3',
        'quarantineStart': 1652926402467,
        'actualQuarantineEnd': 1652996402467,
        'roomCount': 2,
        'accommodationCost': 300,
        'breakFast': 25,
        'lunch': 40,
        'dinner': 40
    }
];

export class JYFCReportQueryModel extends ReportExtraQueryModel {
    @QueryControl({
        label: '楼栋',
        type: QueryControlType.SELECT,
        optionsPromise: getLocation,
        hasAllOption: true,
        span: 4
    })
    locationId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '来源',
        type: QueryControlType.SELECT,
        hasAllOption: true,
        options: sourceList,
        span: 4
    })
    source: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '开始隔离日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 8,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_30_DAY();

    @QueryControl({
        label: '实际离舱日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 8
    })
    actualQuarantineEnd: Array<Moment> = undefined;

    toService() {
        const params: any = {
            locationId: this.locationId,
            source: this.source,
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.DAY)
        };
        if (this.actualQuarantineEnd && this.actualQuarantineEnd.length === 2) {
            params.actualQuarantineEndStartTime = this.actualQuarantineEnd[0].startOf('day').toDate().getTime();
            params.actualQuarantineEndEndTime = getEndTimeByIntervalType(this.actualQuarantineEnd[1], PeriodTypeEnum.DAY);
        }
        return { params };
    }
}

export async function JYFCReportService(queryModel: JYFCReportQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/financialManagement/cost/source/count`;
    const res = await post(url, queryModel?.toService?.() || queryModel);
    // const res = MockData;
    const chartOptions = {
        data: {
            columns: ['source', 'accommodationCost', 'breakfastCost', 'lunchCost', 'dinnerCost'],
            rows: res
        },
        settings: {
            type: 'histogram',
            labelMap: { accommodationCost: '住宿费', breakfastCost: '早餐费', lunchCost: '午餐费', dinnerCost: '晚餐费' }
        },
        extend: {
            series: {
                label: {
                    show: false,
                    color: '#fff',
                    position: 'inside'
                }
            }
        }
    };
    return assignDefaultExtend(chartOptions);
}

export async function JYFCReportTableService(queryModel: JYFCReportQueryModel, page?: number, limit?: number) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/financialManagement/cost/query`;
    const params = Object.assign({ page, limit }, queryModel?.toService());
    const res = await post(url, params);
    // const res = MockData;
    return res;
}

export async function JYFCReportExportService(queryModel: any) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/financialManagement/cost/query/export`;
    const res = await download(url, queryModel, 'post');
    return res;
}
