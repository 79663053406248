

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import { download } from '@common-src/service/request';
import { QueryModel } from '@common-src/model/query-model';
import { JYFCReportExportService } from '@/pages/sub-system/model/jyfc-model';

async function exportExcel(params: any) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/personnelManagement/export`;
    const res = await download(url, params, 'post');
    return res;
}

@Component
export default class QuarantineRoomExportComponent extends FormDialogComponent<any> {
    isCost: boolean;
    dialogOpen(isCost: boolean = false): void {
        this.isCost = isCost;
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '人员信息批量导出';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'dateRange',
                        label: '实际离舱日期范围',
                        type: FormControlType.SELECT_DATERANGE,
                        required: true
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导出，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                const queryModel = new QueryModel();
                const params = {
                    actualQuarantineEndStartTime: this.jtlForm.formModel.dateRange[0].startOf('day').toDate().getTime(),
                    actualQuarantineEndEndTime: queryModel.getEndTime(this.jtlForm.formModel.dateRange[1])
                };
                let exportService = null;
                if (this.isCost) {
                    exportService = JYFCReportExportService;
                } else {
                    exportService = exportExcel;
                }
                exportService(params).then(res => {
                    this.showMessageSuccess('导出成功');
                    this.dialogClose();
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
